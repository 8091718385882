var bookmark = {
  sum: parseInt($(".module--tableofcontents tbody tr").length),
  current: parseInt($(".module--tableofcontents__selected").length)
}

$(".module--bookmark__current").html(bookmark.current);
$(".module--bookmark__sum").html(bookmark.sum);
$(".module--bookmark__reset_unread").hide();

if ( bookmark.current == bookmark.sum ){
  $(".module--bookmark__next_unread").fadeOut();
  $(".module--bookmark__reset_unread").fadeIn();
}

$(".module--bookmark__next_unread").on("click", function(){
  var url = $(".module--tableofcontents tbody tr:not([class~='module--tableofcontents__selected']) a").eq(0).attr("href");
  if (url) window.location = url;
  return false;
});

$(".module--bookmark__reset_unread").on("click", function(){
  tableofcontents.reset();
  var url = $(".module--tableofcontents tbody tr a").eq(0).attr("href");
  if (url) window.location = url;
  return false;
});