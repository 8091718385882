class VideoComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const videoSources = ['iframe[src*="youtube"]', 'iframe[src*="vimeo"]', '.module--video video'];
        this.style.display = "block";

        this.querySelectorAll(videoSources.join(",")).forEach((video) => {
            // Fitvids
            video.style.width = "100%";
            video.style.height = "auto";
            video.style.maxWidth = "100%";
            video.style.aspectRatio = `${video.getAttribute(
                "width"
            )} / ${video.getAttribute("height")}`;
            video.removeAttribute("height");
            video.removeAttribute("width");

            // Randomize the start time of the video
            if (video.hasAttribute('rand')) {
                video.addEventListener('loadedmetadata', function () {
                    this.currentTime = Math.random() * (this.duration - 1) + 1;
                }, false);
            }
        });
    }

    disconnectedCallback() {
    }
}

customElements.define('video-component', VideoComponent);
