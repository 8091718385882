app.components.slideshow = {
    /**
     * Check if there are any slideshow modules on the page.
     * @returns {boolean} True if slideshow modules exist, false otherwise.
     */
    onReady: function () {
        return $('.module--slideshow').length > 0;
    },

    /**
     * Initialize slideshow functionality by loading dependencies and setting up Swipers.
     */
    addListener: async function () {
        try {
            const [Swiper, swiperModules] = await Promise.all([
                import(/* webpackChunkName: "swiper" */ 'swiper'),
                import(/* webpackChunkName: "swiperPagination" */ 'swiper/modules'),
                import(/* webpackChunkName: "swiperNavigation" */ 'swiper/modules'),
                import(/* webpackChunkName: "swiper" */ 'swiper/css')
            ]);

            const SwiperLib = Swiper.default;

            // Initialize all slideshow instances on the page
            $('.module--slideshow').each(function () {
                const container = $(this);

                const swiperParams = {
                    el: $('.swiper-container', container)[0],
                    slidesPerView: 1,
                    speed: 800,
                    spaceBetween: 0,
                    loop: true,
                    lazy: true,
                    wrapperClass: 'swiper-wrapper',

                    modules: [swiperModules.Pagination, swiperModules.Navigation],
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    watchOverflow: true,
                    on: {
                        slideChange: () => {
                            $('.swiper-slide video').each(function () {
                                this.pause();
                            });
                        }
                    }
                };

                // Disable sliding if there is only one slide
                if ($('.swiper-slide', container).length <= 1) {
                    Object.assign(swiperParams, {
                        allowSlidePrev: false,
                        allowSlideNext: false,
                        simulateTouch: false
                    });
                }

                // Apply autoplay if specified
                if (container.data('swiper-autoplay')) {
                    swiperParams.autoplay = { delay: 6000 };
                }

                // Apply scrollbar configuration if specified
                if (container.data('swiper-scrollbar')) {
                    Object.assign(swiperParams, {
                        scrollbar: {
                            el: '.swiper-scrollbar',
                            draggable: true,
                            snapOnRelease: false,
                            hide: false
                        },
                        loop: false,
                        pagination: false,
                        slidesPerView: 'auto'
                    });
                }

                // Include accessibility settings if available
                if (typeof window.swiperAccessibilityParameters !== 'undefined') {
                    swiperParams.a11y = window.swiperAccessibilityParameters;
                };

                let swiperInstance = null;

                $(window).on('resize', () => {
                    const isMobile = window.innerWidth <= 768;
                    swiperParams.pagination.type = isMobile ? 'fraction' : 'bullets';
                    if (swiperInstance) swiperInstance.destroy();
                    swiperInstance = new SwiperLib(swiperParams.el, swiperParams);
                }).trigger('resize');

            });

        } catch (error) {
            console.error('Error loading slideshow modules:', error.message);
        }
    }
};
