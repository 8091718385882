$(function () {

    /* FORMS
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    $("body").on("submit", ".module--form form", (function () {

        var form = $(this);
        form.addClass('isload');

        $.ajax({
            url: form.attr('action') + '?ajax=1',
            data: form.serialize(),
            type: 'post',
            cache: false,
            dataType: 'html',
            success: function (data) {
                form.html(data).removeClass('isload');
                formOnLoad();
            }
        });

        return false;

    }));

    function formOnLoad() {

        /* JCF/SELECTRIC PLUGIN
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

        Promise.all([
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.radio.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.checkbox.js')
        ]).then(() => {
            jcf.replaceAll();
        }).catch(error => {
            console.error('Error loading modules:', error);
        });
        $('select').selectric();

        /* PLACEHOLDER SUPPORT
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
        $('input, textarea').placeholder();

        /* FLOATING LABEL
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
        $('div.woocommerce input, div.woocommerce textarea').on('input', function () {
            var field = $(this);
            var placeholder = field.attr('placeholder');
            // remove placeholder when label isset
            if (!field.prev('label').text() && placeholder) {
                $('<label>' + placeholder + '</label>').insertBefore(field);
            }
            field.attr('placeholder', '');
            field.prop('placeholder', null);
            field.removeAttr('placeholder');
        }).trigger('input');

        setTimeout(function () {
            $('.woocommerce input, .woocommerce textarea').trigger('input');
        }, 500);

        $('input, textarea').on('input', function () {
            var $field = $(this).closest('p');
            if (this.value) {
                $field.addClass('field--not-empty');
            } else {
                $field.removeClass('field--not-empty');
            }
        }).trigger('input');
        $('.notes textarea').removeAttr('placeholder');

        /* Artclubinquiry
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
        if ($('#Artclubinquiry').length) {

            $('input[name="formArtclubinquiryaction"]').on('change', function () {

                $(this).each(function () {
                    if ($(this).is(":checked")) {
                        $('.membership').hide();
                        var id = $(this).attr('id');
                        switch (id) {
                            case 'formArtclubinquiryaction0':
                                $('.membership_0').show();
                                break;
                            case 'formArtclubinquiryaction1':
                                $('.membership_1').show();
                                break;
                            case 'formArtclubinquiryaction2':
                                $('.membership_3').show();
                                break;
                        }
                    }
                });

            }).trigger('change');

            $('#formArtclubinquirygiveaway').on('change', function () {
                $('div.giveaway').hide();
                if ($(this).is(":checked")) {
                    $('div.giveaway').show();
                }
            }).trigger('change');

        }

        /* Journeyinquiry
        +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
        if ($('#Journeyinquiry').length) {

            $('input[name="formJourneyinquirydifferentbilling"]').on('change', function () {

                if ($(this).is(":checked")) {
                    $('fieldset.billing').fadeIn();
                } else {
                    $('fieldset.billing').fadeOut();
                }
            }).trigger('change');

        }

    }

    formOnLoad();

});
