$(function () {
    var pressfiles = {
        limit: 10,
        page: 0,
        filter: [],
        doFilter: function () {
            var obj = this;
            var begin = obj.page * obj.limit;
            var end = (obj.page + 1) * obj.limit;
            obj.removeFilter();
            $("a[href$='#more--pressfiles']").hide();
            $('.module--pressfiles__entry').hide();
            $('.module--pressfiles__entry').slice(0, end).show();
            if ($(".module--pressfiles__entry").length > end) {
                $("a[href$='#more--pressfiles']").fadeIn();
            }
        },
        addFilter: function (filter) {
            this.filter.push(filter);
        },
        removeFilter: function () {
            this.filter = [];
        }
    };
    $("a[href$='#more--pressfiles']").on("click", function () {
        pressfiles.doFilter();
        pressfiles.page++;
        return false;
    }).trigger("click");
});
