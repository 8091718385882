$(function () {

    var events = {
        limit: 10,
        page: 0,
        filter: [],
        doFilter: function () {
            var obj = this;
            var begin = obj.page * obj.limit;
            var end = (obj.page + 1) * obj.limit;
            obj.removeFilter();
            if ($("input[name='event__location']:checked").length) {
                $("input[name='event__location']:checked").each(function () {
                    var filter = ".event__location--" + $(this).val();
                    if ($("select[name='event__topic']").val()) {
                        filter += ".event__topic--" + $("select[name='event__topic']").val();
                    }
                    obj.addFilter(filter);
                });
            } else if ($("select[name='event__topic']").val()) {
                obj.addFilter(".event__topic--" + $("select[name='event__topic']").val());
            }
            $("a[href$='#more--events']").hide();
            $('.module--events__entry').hide();
            if (obj.filter.length) {
                $(obj.filter).each(function (i, v) {
                    $(v).show();
                });
            } else {
                $('.module--events__entry').slice(0, end).show();
                if ($(".module--events__entry").length > end) {
                    $("a[href$='#more--events']").fadeIn();
                }
            }
        },
        addFilter: function (filter) {
            this.filter.push(filter);
        },
        removeFilter: function () {
            this.filter = [];
        }
    };

    $("a[href$='#more--events']").on("click", function () {
        events.doFilter();
        events.page++;
        return false;
    }).trigger("click");

    $("select[name='event__topic']").on("change", function () {
        events.doFilter();
    });

    $("input[name='event__location']").on("click", function () {
        events.doFilter();
    });

    $(".module--events__entry").accordion({
        active: false,
        heightStyle: "content",
        collapsible: true,
        multiple: true,
    });

    $(".module--events__entry h3 a").click(function () {
        window.location = $(this).attr('href');
        return false;
    });

    if (window.location.hash) {
        $("select[name='event__topic'] option").removeAttr('selected');
        $("select[name='event__topic'] option").each(function () {
            if ("#" + $(this).text() == window.location.hash) {
                $(this).attr('selected', true);
                $("select[name='event__topic']").trigger("change").selectric('refresh');
            }
        });
    }

});
