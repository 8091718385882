import { ResizeSensor } from 'css-element-queries';


$(function() {
    /* IS TOUCH DEVICE
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    function is_touch_device() {
        return 'ontouchstart' in window;
    }


    /* REMOVE NO-JS CLASS
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    $("html").addClass(is_touch_device() ? 'touch' : 'click');


    /* ADD PDF CLASS & OPEN NEW WINDOW ON CLICK
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    $("a[href$='pdf']").attr("target", "_blank");


    /* Open External Links In New Window
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    $('a').each(function () {
        var a = new RegExp('/' + window.location.host + '/');
        if (!a.test(this.href)) {
            $(this).attr("target", "_blank");
        }
    });


    /* HASIMAGE
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    $('a:has(img)').addClass('hasImage');

    /* SLIDEBARS PLUGIN
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    $('.slidebar-inner').html(
        $('<div>').append($('header .module--navigation__primary').clone()).html() +
        $('<div>').append($('header .module--navigation__secondary').clone()).html() +
        $('<div>').append($('header .module--language').clone()).html()
    );

    $('.slidebar .menu_item_dropdown > a').on('click', function () {
        var el = $(this).parent('li');
        if ($(el).hasClass("active")) {
            $(el).removeClass("active");
        } else {
            $(el).addClass("active");
        }
        return false;
    });

    $('header button').on('click', function () {
        $(this).toggleClass('is-active');
        $('html').toggleClass('open-slidebar');
    });


    /* PARALLAX BACKRGOUND
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    /*$('.column--youtube a').each(function(){
      $(this).css({'background-image': 'url('+$('img',this).fadeTo(0,0).attr('src')+')'});
    });*/

    $('.column--youtube a').on('click', function () {
        var video = $(this).attr('href');
        var height = $(this).parent('div').height();
        var width = $(this).parent('div').width();
        $(this).parent('div').html('<iframe width="' + width + '" height="' + height + '" src="' + video + '?autoplay=1" frameborder="0" allowfullscreen></iframe>');
        return false;
    });


    /* VIEWPORT BASED ANIMATION
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    // disable animations on...
    $('.column--newsletter .module, .module--teaser, .module--slideshow, .module--footer').addClass('noAnimation');


    /* SHRINK HEADER
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    var intro = {};
    $(window).on('scroll resize', function () {
        intro.pageY = $(document).height();
        intro.scrollY = $(window).scrollTop();
        intro.offset = parseInt((intro.scrollY / intro.pageY) * 100);
        if (!intro.lastScrollY) intro.lastScrollY = 0;
        if (intro.scrollY > 10) {
            $('body').not("shrinkOn").addClass("shrinkOn");
            if (intro.offset >= 5) {
                $('.module--teaser .swiper-slide-content').addClass('animated fadeOutUp');
            } else {
                $('.module--teaser .swiper-slide-content').removeClass('fadeOutUp').addClass('animated fadeIn');
            }
        } else {
            $('body.shrinkOn').removeClass("shrinkOn");
            $('.module--teaser .swiper-slide-content').removeClass('fadeOutUp').addClass('animated fadeIn');
        }
        if (intro.scrollY <= intro.lastScrollY) {
            $('body').not("shrinkTop").addClass("shrinkTop");
        } else {
            $('body').removeClass("shrinkTop");
        }
        intro.lastScrollY = intro.scrollY;
    }).trigger("resize");

    function addContentOffset() {
        var offset = parseInt($('header').outerHeight());
        if ($('.module--teaser').length) {
            offset = parseInt($('.module--teaser').outerHeight());
        }
        $('#page').css({'paddingTop': offset});
    }

    addContentOffset();

    new ResizeSensor($('header'), function () {
        addContentOffset();
    });
    new ResizeSensor($('.module--teaser'), function () {
        addContentOffset();
    });

    $('.module--teaser img').waitForImages({waitForAll: true}).done(function () {
        $(window).trigger("resize");
    });


    /* HASH LINK SCROLL ANIMATION
    +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    $('header a[href^="#"]').on('click touchstart', function (e) {
        var hash = $(this).attr('href');
        if (!hash.length || !$(hash).length) return;
        $('header').removeClass('shrinkOff').addClass('shrinkOff');
        $('html, body').stop().animate({
            'scrollTop': $(hash).offset().top
        }, 750, 'swing', function () {
            //window.location.hash = hash;
            $(document).trigger("scroll");
        });
        return false;
    });
});
