import 'jquery-ui/ui/widgets/accordion';

$(function () {

    $(".module--accordion .module--accordion-inner").accordion({
        active: false,
        heightStyle: "content",
        collapsible: true
    });

});
